import React from 'react';
import ImageModal from './ImageModal';
import riqasImg from '../images/riqas.webp';

export default (props) => {
  return(
    <div className="my-8">
      <div className="flex mt-2 p-4 bg-white shadow-md items-center">
        <div>
          <ImageModal src="https://i.imgur.com/56WLWxh.png" alt="Imagen con la certificación PACAL en Hematología y Urianálisis" className="max-w-md" />
          <div className="block mt-2">
            <ImageModal src="https://i.imgur.com/rqfdfU1.png" alt="Imagen con la certificación PACAL en Hematología y Urianálisis" className="max-w-md" />
          </div>
        </div>
        <div className="flex-1 ml-4">
          <p className="font-bold text-2xl mb-8">PACAL</p>
          <p className="mb-2">Es una empresa que certifica el control de calidad de los laboratorios de análisis clínicos en el país.</p>
          <p className="mb-2">PACAL certifica a DIAGNOSUR con base en evaluaciones realizadas de manera mensual en las siguientes áreas:</p>
          <ul>
            <li>Química Clínica</li>
            <li>Uroanalisis</li>
            <li>Bacteriologia</li>
            <li>Hematología</li>
            <li>Parasitología</li>
            <li>Citometría Hemática</li>
            <li>Coproparasitoscópicos</li>
          </ul>
        </div>

      </div>

      <div className="flex mt-12 p-4 bg-white shadow-md items-center">
        <div>
          <ImageModal src={riqasImg} alt="Imagen con la certificación PACAL en Hematología y Urianálisis" className="max-w-md" />

        </div>
        <div className="flex-1 ml-4">
          <p className="font-bold text-2xl mb-8">RIQAS</p>
          <p className="mb-2">Es una empresa que certifica el control de calidad de los laboratorios de análisis clínicos a nivel internacional, con presencia en el continente americano y europeo.</p>
          <p className="mb-2">RIQAS certifica a DIAGNOSUR con base en evaluaciones realizadas de manera mensual en el área de pruebas especiales</p>

        </div>

      </div>
    </div>
  )
}