import React, { useState, Fragment }  from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width:'80vw',
    height:'70vh',
    position: 'relative'
  }
};

Modal.setAppElement("#___gatsby");

export default ({src, alt, className,style})=>{
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  return(
    <Fragment>
      <img style={Object.assign({}, { cursor: "pointer" }, style)} src={src} alt={alt} className={className + " hover:shadow-md hover:opacity-75"} onClick={()=> setmodalIsOpen(true)} />
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        
      >
        <button style={{top:"1em", right:"1em"}} className="absolute text-2xl" onClick={() => setmodalIsOpen(false)}>X</button>
        <div className="flex items-center justify-center bg-white z-50 h-full w-full">
          { modalIsOpen && <img src={src} alt={alt} className="max-h-full max-w-full" />}
        </div>
      </Modal>
    </Fragment>
  )
}