import React from 'react';
import Layout from '../components/layout';
import PageHeader from '../components/PageHeader';
import Container from '../components/Container';
import { Link } from 'gatsby';
import Footer from '../components/Footer';
import Certificaciones from '../components/Certificaciones';

export default ()=>{
  return(
    <Layout>
      <PageHeader>
        <h2 className="text-6xl">Certificaciones</h2>
        <p className="mt-2 text-sm">
          <Link to="/" className="underline">Inicio</Link>
          <i className="fas fa-chevron-right mx-2"></i>
          <Link to="/certificaciones" className="underline">Certificaciones</Link>
        </p>
        {/* <p className="my-4 max-w-xl mx-auto opacity-75 text-sm">{category.description}</p> */}
      </PageHeader>
      <Container>
        <Certificaciones />       
        
      </Container>
      <Footer></Footer>
    </Layout>

  )
}